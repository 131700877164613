import {
  IWidgetController,
  IControllerConfig,
  I$W,
} from '@wix/native-components-infra/dist/src/types/types';
import { IWidgetControllerConfig } from './platform.types';

import { SearchResultsControllerStore } from './SearchResultsControllerStore';

export interface ISearchResultsWixCode {
  changeQuery(query: string): void;
}

export async function searchResultsControllerFactory(
  controllerConfig: IWidgetControllerConfig,
): Promise<IWidgetController> {
  let controllerStore: SearchResultsControllerStore;

  try {
    controllerStore = new SearchResultsControllerStore(controllerConfig);
  } catch (error) {
    controllerConfig.reportError(error);
    throw error;
  }

  return {
    pageReady() {
      return controllerStore.setInitialState();
    },

    updateConfig($w: I$W, config: IControllerConfig) {
      void controllerStore.updateConfig(config);
    },

    exports: {
      // NOTE: used for mocks in tests
      searchSDK: controllerStore.getSearchSDK(),
      changeQuery: controllerStore.changeQuery,
    } as ISearchResultsWixCode,
  };
}
